import {
  StatusProps, StatusFeaturesIcons, IconLookup,
  trailLegendSummer, trailFilter, SnowfallUnits, Symbols,
  ParkingStatus,
} from '@powdr/constants';
import {
  camalize, themeUtil, componentColor, formatDate,
} from '@powdr/utils';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const milesToKilometers = (miles) => miles * 1.6;

export const setTrailCondBySecLabel = (step1, step2, step3, season = 'winter') => (
  // eslint-disable-next-line no-nested-ternary
  season === StatusProps.SEASON.WINTER
    ? step1
    : (season === StatusProps.SEASON.SUMMER)
      ? step2
      : step3);

export const setMinRange = (seconds) => {
  const min = Math.floor(seconds / 60); // minutes

  if (min <= 2) {
    return '0-2 mins';
  } if (min >= 2 && min <= 5) {
    return '2-5 mins';
  } if (min >= 5 && min <= 10) {
    return '5-10 mins';
  } if (min >= 10 && min <= 20) {
    return '10-20 mins';
  } if (min >= 20) {
    return '20+ mins';
  }

  return '-';
};

export const setWeatherSliderObj = ({
  name,
  order,
  temp,
  tempMin,
  tempMax,
  shortLabel,
  longLabel,
  snow,
  snowMin,
  snowMax,
  icon,
  date,
}) => ({
  name,
  order,
  icon,
  date: new Date(date),
  temp: {
    day: Math.round(temp || 0),
    min: Math.round(tempMin || 0),
    max: Math.round(tempMax || 0),
  },
  snow: (snowMin !== null && snowMax !== null) ? {
    day: Math.round(snow || 0),
    min: Math.round(snowMin || 0),
    max: Math.round(snowMax || 0),
  } : null,
  forecast: {
    short: shortLabel || null,
    long: longLabel || null,
  },
  ...formatDate(new Date(date))
  ,
});

export const setDorDetailsSetting = (data, findStr) => {
  const settings = data;
  if (settings) {
    for (let i = 0; i < settings.length; i++) {
      if (settings[i].component === findStr) {
        return settings[i];
      }
    }
  }

  return false;
};

export const getIcon = (dorCode) => Object.keys(IconLookup)
  .find((icon, index) => ((Object.values(IconLookup)[index]
    .includes(dorCode))
    ? Object.keys(IconLookup)[index]
    : null));

export const setStatus = (status, type = 'trail') => {
  const obj = {
    id: null,
    label: null,
    icon: null,
  };

  if (type === 'lift' || type === 'poi') {
    if (status === 'opening' || status === 'open') {
      obj.id = 'opening';
      obj.label = 'Open';
      obj.icon = 'opening';
    }

    if (status === 'on-hold') {
      obj.id = 'onHold';
      obj.label = 'On Hold';
      obj.icon = 'on-hold';
    }

    if (status === 'expected') {
      obj.id = 'expected';
      obj.label = 'Expected';
      obj.icon = 'expected';
    }

    if (status === 'closed' || status === 'close') {
      obj.id = 'closed';
      obj.label = 'Closed';
      obj.icon = 'closed';
    }
  }

  if (type === 'trail') {
    if (status === 'opening' || status === 'open') {
      obj.id = 'opening';
      obj.label = 'Open';
      obj.icon = 'opening';
    }

    if (status === 'opened_and_groomed') {
      obj.id = 'opening';
      obj.label = 'Open & Groomed';
      obj.icon = 'opening';
    }

    if (status === 'expected') {
      obj.id = 'expected';
      obj.label = 'Expected Opening';
      obj.icon = 'expected';
    }

    if (status === 'on_hold' || status === 'on-hold') {
      obj.id = 'onHold';
      obj.label = 'On Hold';
      obj.icon = 'on-hold';
    }

    if (status === 'noon_groomed') {
      obj.id = 'groomedAm';
      obj.label = 'Noon Groomed';
      obj.icon = 'groomed-am';
    }

    if (status === 'grooming') {
      obj.id = 'groomed';
      obj.label = 'Groomed';
      obj.icon = 'groomed';
    }

    if (status === 'closed' || status === 'close') {
      obj.id = 'closed';
      obj.label = 'Closed';
      obj.icon = 'closed';
    }

    if (status === 'grooming') {
      obj.id = 'groomed';
      obj.label = 'Groomed';
      obj.icon = 'groomed';
    }
  }

  return obj;
};

export const setGroomed = (status) => {
  const obj = {
    id: null,
    label: null,
    icon: null,
  };

  if (status === 'grooming') {
    obj.id = 'groomedRecent';
    obj.label = 'Groomed';
    obj.icon = 'feature-groomed-recent';
  }

  if (status === 'groomed_pm') {
    obj.id = 'groomedPm';
    obj.label = 'Noon Groomed';
    obj.icon = 'feature-groomed-pm';
  }

  if (status === 'groomed_am') {
    obj.id = 'groomedAm';
    obj.label = 'Noon Groomed';
    obj.icon = 'feature-groomed-am';
  }

  if (status === 'not_groomed') {
    obj.id = null;
    obj.label = null;
    obj.icon = null;
  }

  return obj;
};

export const checkStatusAgain = (str, arr) => {
  for (let q = 0; q < arr.length; q++) {
    if (arr[q].status_name === str) {
      return arr[q].status_value;
    }
  }
};

export const setDifficulty = (str) => {
  switch (str) {
    case 'easiest':
      return {
        id: 'green',
        label: 'level-green',
        icon: 'difficulty-level-green',
        order: 0,
      };

    case 'more_difficult':
      return {
        id: 'blue',
        label: 'level-blue',
        icon: 'difficulty-level-blue',
        order: 1,
      };

    case 'most_difficult':
      return {
        id: 'black',
        label: 'level-black',
        icon: 'difficulty-level-black',
        order: 2,
      };

    case 'extreme':
      return {
        id: 'black2',
        label: 'level-black-2',
        icon: 'difficulty-level-black-2',
        order: 3,
      };

    case 'extreme_terrain':
      return {
        id: 'black3',
        label: 'level-black-3',
        icon: 'difficulty-level-black-3',
        order: 3,
      };

    default:
      return {};
  }
};

export const setFeatureSizes = (size) => trailFilter[2].fields.filter((val) => val.id === size);

export const setLiftTypeDisplay = (type) => {
  switch (type) {
    case 'quad':
      return {
        id: 'quad',
        label: 'quad',
        icon: 'lift-quad',
        order: 0,
      };

    case 'double':
      return {
        id: 'double',
        label: 'double',
        icon: 'lift-double',
        order: 1,
      };

    case 'triple':
      return {
        id: 'triple',
        label: 'triple',
        icon: 'lift-triple',
        order: 2,
      };

    case 'gondola':
      return {
        id: 'gondola',
        label: 'gondola',
        icon: 'lift-gondola',
        order: 3,
      };

    default:
      return null;
  }
};

export const setFeaturedDifficulty = (features, difficulty) => {
  if (!features) return [];
  const list = Object.entries(features)
    ?.filter(([_, value]) => value === true)
    ?.map(([key]) => key);

  const icons = trailLegendSummer
    .filter((item) => list.includes(item.id))
    .map((v) => {
      const i = v.icons
        ?.filter((item) => item.id === difficulty)
        ?.map((icon) => ({
          ...icon,
          id: v.id,
        }));
      return i;
    })
    ?.filter((notUndefined) => notUndefined !== undefined)
    ?.flat();

  return icons;
};

export const setFeature = (features) => Object
  .entries(features || {})
  .filter(([_, val]) => (val === true))
  .map(([key]) => StatusFeaturesIcons[key]);

export const roundIt = (value, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const convertCmsSetToBools = (s, ps) => {
  const mapped = Object
    ?.entries(ps)
    ?.map(([k, v]) => ({ [k]: !s?.includes(v) || false }));
  return Object.assign({}, ...mapped);
};

export const getNormalizedId = (type, consts) => {
  const results = Object
    .values(consts)
    .filter((v) => (Array.isArray(v) && v.includes(type))
      || v === type)
    .map(([k]) => camalize(k));

  return results.length === 1 ? results[0] : results;
};

export const getNormalizedIds = (types, consts) => types
  .map((v) => getNormalizedId(v, consts)).flat();

export const getIconNameFromUrl = (url) => {
  const parse = url
    .slice(0, url
      .indexOf('?'))
    .substring(url
      .lastIndexOf('/') + 1);
  const [name] = parse.split(',');
  return name;
};

export const getSnowfallMeasurementUnit = () => (
  (gatsbyConfig?.dor?.snowfallUnit !== undefined && gatsbyConfig?.dor?.snowfallUnit !== null)
    ? gatsbyConfig?.dor?.snowfallUnit
    : SnowfallUnits.INCHES
);

export const getDorComponentColor = (isParentNavFeed, colorProfile, prop, component, fallback) => (
  (!isParentNavFeed)
    ? themeUtil(prop, component, colorProfile)
    : componentColor(prop, component, fallback)
);

export const temperatureStringToInt = (s) => parseInt(s.replace(Symbols.DEGREE, ''), 10);

export const findStatusTrailSubType = (values, types) => {
  const findKey = (value) => Object.keys(types)
    ?.find((key) => {
      const subTypes = types[key];
      if (Array.isArray(subTypes)) {
        return subTypes.includes(value);
      }
      return subTypes === value;
    });

  return values.map((value) => {
    const key = findKey(value);
    if (key) {
      return key.toLowerCase();
    }
    return null;
  });
};

export const getParkingAmountString = (v, m) => {
  const percentage = Math.round((v / m) * 100);

  if (percentage <= 35) {
    return ParkingStatus.EMPTY;
  }
  if (percentage > 35 && percentage <= 99) {
    return ParkingStatus.FILLING_UP;
  }

  return ParkingStatus.FULL;
};
