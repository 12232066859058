import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSnowReport } from '@powdr/stores/actions/snow-report-action';
import { getSnowfallMeasurementUnit } from '@powdr/utils';

export const useSnowfallMeasurement = () => {
  const dispatch = useDispatch();

  const useSnowReportData = useSelector((state) => state.snowReport);
  const { snowReport } = useSnowReportData;
  const [snowfallMeasurement, setSnowfallMeasurement] = useState({
    value: null,
    unit: getSnowfallMeasurementUnit(),
  });

  useEffect(() => {
    dispatch(getSnowReport());
  }, [dispatch]);

  useEffect(() => {
    if (snowReport?.measurements) {
      setSnowfallMeasurement({
        value: snowReport?.measurements['24_hour'],
        unit: getSnowfallMeasurementUnit(),
      });
    }
  }, [snowReport?.measurements]);

  return snowfallMeasurement;
};
