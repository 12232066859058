import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@powdr/components';
import { GlobalLayoutValues } from '@powdr/constants';
import { componentFactory } from '@powdr/utils';

import {
  StyledSidebarTray,
  SidebarTrayInner,
  ComponentWrapper,
  TrayDecoration,
  CloseButtonMobile,
  CloseButtonDesktop,
  SidebarTrayAnimationWrapper,
} from './styles';

export const SidebarTray = ({
  className,
  component,
  isOpen,
  positionalData,
  itemIndex,
  handleDrawer,
}) => (
  <StyledSidebarTray
    className={className}
    $isOpen={isOpen}
    $offsetWidth={positionalData?.offsetWidth || 0}
    $height={positionalData?.height || 0}
    $sidebarHeight={positionalData?.sidebarHeight || 0}
    $component={component}
  >
    <SidebarTrayAnimationWrapper
      className={className}
      $isOpen={isOpen}
      $offsetWidth={positionalData?.offsetWidth || 0}
      $height={positionalData?.height || 0}
      $sidebarHeight={positionalData?.sidebarHeight || 0}
      $component={component}
    >
      <CloseButtonMobile onClick={() => handleDrawer(null)} $component={component}>
        <Icon name="ui-close-circle" height={40} width={40} />
      </CloseButtonMobile>
      <SidebarTrayInner $isOpen={isOpen}>
        <CloseButtonDesktop onClick={() => handleDrawer(null)} $component={component}>
          <Icon name="ui-close-circle" height={40} width={40} />
        </CloseButtonDesktop>
        {(component) && (
        <ComponentWrapper>
          {componentFactory([component], undefined, undefined, {
            height: positionalData.height + (GlobalLayoutValues.DESKTOP.SIDEBAR.PADDING * 2),
            minHeight: positionalData.sidebarHeight,
            handleDrawer,
            itemIndex,
          })}
        </ComponentWrapper>
        )}
        <TrayDecoration $component={component} />
      </SidebarTrayInner>
    </SidebarTrayAnimationWrapper>
  </StyledSidebarTray>
);

SidebarTray.propTypes = {
  className: PropTypes.string,
  component: PropTypes.shape({
    typeName: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  positionalData: PropTypes.shape({
    sidebarHeight: PropTypes.number,
    offsetWidth: PropTypes.number,
    height: PropTypes.number,
  }),
  itemIndex: PropTypes.number.isRequired,
  handleDrawer: PropTypes.func.isRequired,
};

SidebarTray.defaultProps = {
  className: '',
  positionalData: {
    offsetWidth: 0,
    height: 0,
  },
};
