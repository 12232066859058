import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  StyledDorPercentWheel,
  ProgressSVG, ProgressTextContainer,
  TextPrimary, TextSecondary,
} from './styles';

export const DorPercentWheel = ({
  parentColorProfile,
  totalVal,
  openVal,
  strokeWidth,
  isParentNavFeed,
  colorOverride,
  children,
}) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [strokeDasharray, setStrokeDasharray] = useState(0);
  const radiusAbsolute = 64;
  const circleRadius = radiusAbsolute - (strokeWidth / 2);

  useEffect(() => {
    const percent = Math.round((openVal / totalVal) * 100);
    const progress = percent / 100;
    const circ = 2 * Math.PI * (circleRadius);
    const dashoffset = circ * (1 - progress);

    setStrokeDasharray(circ);
    setStrokeDashoffset(dashoffset);
  }, [strokeDasharray, strokeDashoffset, openVal, totalVal, circleRadius]);

  return (
    <StyledDorPercentWheel colorProfile={parentColorProfile} isParentNavFeed={isParentNavFeed}>
      <ProgressTextContainer>
        {(!children) ? (
          <>
            <TextPrimary>{openVal}</TextPrimary>
            <TextSecondary>{`of ${totalVal}`}</TextSecondary>
          </>
        ) : children}
      </ProgressTextContainer>
      <ProgressSVG viewBox={`0 0 ${radiusAbsolute * 2 + 1} ${radiusAbsolute * 2 + 1}`} $colorOverride={colorOverride}>
        <circle
          className="meter"
          cx={radiusAbsolute}
          cy={radiusAbsolute}
          r={circleRadius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="value"
          cx={radiusAbsolute}
          cy={radiusAbsolute}
          r={circleRadius}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </ProgressSVG>
    </StyledDorPercentWheel>
  );
};

DorPercentWheel.propTypes = {
  totalVal: PropTypes.number,
  openVal: PropTypes.number,
  strokeWidth: PropTypes.number,
  parentColorProfile: PropTypes.number,
  isParentNavFeed: PropTypes.bool,
  colorOverride: PropTypes.string,
  children: PropTypes.node,
};

DorPercentWheel.defaultProps = {
  totalVal: null,
  openVal: null,
  strokeWidth: 24,
  parentColorProfile: null,
  isParentNavFeed: false,
  colorOverride: null,
  children: null,
};
