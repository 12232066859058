import PropTypes from 'prop-types';
import React, {
  useEffect, useContext, useMemo, useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { DorForecastItem, PrimaryCarousel, WysiwygStyleWrapper } from '@powdr/components';
import { CarouselControlPlacement } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getWeather } from '@powdr/stores/actions/weather-action';

import {
  StyledDorWeather,
  StaticHeader,
  OpenWeatherAttribution,
  ForecastList,
  ForecastListItem,
} from './styles';

export const DorWeather = ({
  title,
  html,
  parentColorProfile,
  isParentNavFeed,
}) => {
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { data: weather } = useWeatherData;
  const { isMobile } = useContext(AppContext);
  const [forecast, setForecast] = useState([]);
  const descriptionLength = '';

  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  useEffect(() => {
    if (weather?.forecast) {
      setForecast(weather.forecast);
    }
  }, [weather, setForecast]);

  const ForecastMap = useMemo(() => forecast?.map((day) => (
    <ForecastListItem key={day.id}>
      <DorForecastItem
        icon={day?.weather_icon}
        temperature={{
          min: day?.temperature_low,
          max: day?.temperature_high,
        }}
        snowfall={{
          min: 0,
          max: 0,
        }}
        date={day?.day}
        description={(day?.description_long && descriptionLength === 'long')
          ? day.description_long
          : day?.description_short || null}
        isFitToContent={isParentNavFeed}
      />

    </ForecastListItem>
  ) || []), [isParentNavFeed, forecast]);

  return (
    <StyledDorWeather
      colorProfile={parentColorProfile}
      isParentNavFeed={isParentNavFeed}
    >
      <>
        {(!isParentNavFeed && (title || html)) && (
          <StaticHeader>
            {title && (<h3>{title}</h3>)}
            {html && (
              <WysiwygStyleWrapper colorProfile={parentColorProfile}>
                {ReactHtmlParser(html)}
              </WysiwygStyleWrapper>
            )}
          </StaticHeader>
        )}
        {weather?.attribution && (
          <OpenWeatherAttribution aria-label="Powered By OpenSnow" href={weather?.attribution?.link_url}>
            Powered By:
            <img src={weather?.attribution?.image_light_url} alt="" />
          </OpenWeatherAttribution>
        )}
        <ForecastList>
          {isParentNavFeed || isMobile ? (
            <PrimaryCarousel
              controlSettings={{
                navigation: {
                  iconName: 'ui-arrow-left',
                  size: 30,
                  placement: CarouselControlPlacement.IN_WRAPPER,
                },
              }}
              slidesPerView={isMobile ? 1 : 3}
              isInfiniteScroll={false}
              colorProfile={parentColorProfile}
            >
              {ForecastMap}
            </PrimaryCarousel>
          ) : ForecastMap}
        </ForecastList>
      </>
    </StyledDorWeather>
  );
};

DorWeather.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorWeather.defaultProps = {
  title: null,
  html: null,
  isParentNavFeed: false,
  parentColorProfile: null,
};
