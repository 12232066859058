import { FetchStatus } from '@powdr/constants';

import { ALERT_PENDING, ALERT_SUCCESS, ALERT_ERROR } from '../types';

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case ALERT_SUCCESS:
      return {
        ...state,
        data: action.payload[action.payload.length - 1],
        status: FetchStatus.SUCCESS,
      };
    case ALERT_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
